import Vue from "vue";
import store from "@/store";

Vue.mixin({
  methods: {
    isAuthenticated: function () {
      return store.getters["auth/isAuthenticated"];
    },
  },
});
